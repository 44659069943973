import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom"
import HomePage from "./pages/Homepage.tsx"
import Navbar from "./components/Navbar.tsx"
import Footer from "./components/Footer.tsx"
import GalleryPage from './pages/GalleryPage.tsx';
import CertificatePage from './pages/CertificatePage.tsx';
import ProductSourcingPage from './pages/ProductSourcingPage.tsx';
import ScrollToTop from './scrollToTop.tsx';
import ErrorPage from "./pages/ErrorPage.tsx";
import SecondGallery from "./pages/SecondGallery.tsx"
import FirstGallery from './pages/FirstGallery.tsx';
import ThirdGallery from "./pages/ThirdGallery.tsx";
function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/certificate" element={<CertificatePage />} />
          <Route path="/product-sourcing" element={<ProductSourcingPage />} />
          <Route path="/gallery_01072047xSJK23" element={<FirstGallery />} />
          <Route path="/gallery_04072047xSJK23" element={<SecondGallery />} />
          <Route path="/IMPORT-AUG-24" element={<ThirdGallery />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
