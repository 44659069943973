import React , {useRef , useEffect} from 'react'
import "../css/component css/Carousel.css"
import { Link } from 'react-router-dom';

const g1 = require("../images/g1.jpeg");
const g2 = require("../images/g2.jpeg");
const g3 = require("../images/g3.jpeg");
const g4 = require("../images/g4.jpeg");
const g5 = require("../images/g5.jpeg");
const g6 = require("../images/g6.jpeg");
const g7 = require("../images/RICE FLOUR - JUL-2024/j1.jpeg");
const g8 = require("../images/RICE FLOUR - JUL-2024/j2.jpeg");
const g9 = require("../images/RICE FLOUR - JUL-2024/j3.jpeg");
const g10 = require("../images/RICE FLOUR - JUL-2024/j4.jpeg");
const g11 = require("../images/RICE FLOUR - JUL-2024/j5.jpeg");
const g12 = require("../images/RICE FLOUR - JUL-2024/j6.jpeg");
const g13 = require("../images/RICE FLOUR - JUL-2024/j7.jpeg");
const g14 = require("../images/IMPORT-AUG-24/1.jpeg");
const g15 = require("../images/IMPORT-AUG-24/2.jpeg");
const g16 = require("../images/IMPORT-AUG-24/7.jpeg");
const g17 = require("../images/IMPORT-AUG-24/18.jpeg");
const g18 = require("../images/IMPORT-AUG-24/13.jpeg");

export default function Carousel() {
  const carouselRef = useRef<HTMLDivElement>(null);

  const moveForward = () => {
    if (carouselRef.current) {
      const maxScrollLeft =
        carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
      if (
        carouselRef.current.scrollLeft + carouselRef.current.clientWidth >=
        maxScrollLeft - 1
      ) {
        carouselRef.current.scrollTo({ left: 0, behavior: "smooth" });
      } else {
        carouselRef.current.scrollBy({ left: 2000, behavior: "smooth" });
      }
    }
  };

  const moveBackward = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -2000, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      moveForward();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      moveForward();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider-component">
      <div className="carousel" ref={carouselRef} id="carousel">
        <Link to="/gallery">
          <img src={g1} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g2} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g3} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g4} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g5} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g6} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g7} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g8} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g9} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g10} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g11} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g12} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g13} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g14} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g15} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g16} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g17} alt="" aria-label="go to gallery" />
        </Link>
        <Link to="/gallery">
          <img src={g18} alt="" aria-label="go to gallery" />
        </Link>
      </div>
      <button className="btn-a orange-btn next-btn" onClick={moveForward}>
        &gt;
      </button>
      <button className="btn-a orange-btn previous-btn" onClick={moveBackward}>
        &lt;
      </button>
    </div>
  );
}
