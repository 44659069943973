import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import '../css/page css/FirstGallery.css';

const g1 = require("../images/RICE FLOUR - JUL-2024/j1.jpeg");
const g2 = require("../images/RICE FLOUR - JUL-2024/j2.jpeg");
const g3 = require("../images/RICE FLOUR - JUL-2024/j3.jpeg");
const g4 = require("../images/RICE FLOUR - JUL-2024/j4.jpeg");
const g5 = require("../images/RICE FLOUR - JUL-2024/j5.jpeg");
const g6 = require("../images/RICE FLOUR - JUL-2024/j6.jpeg");
const g7 = require("../images/RICE FLOUR - JUL-2024/j7.jpeg");;
const v1 = require("../videos/v1.mp4");


const media = [
  { type: 'video', src: v1 },
  { type: 'image', src: g1 },
  { type: 'image', src: g2 },
  { type: 'image', src: g3 },
  { type: 'image', src: g4 },
  { type: 'image', src: g5 },
  { type: 'image', src: g6 },
  { type: 'image', src: g7 },
];

export default function SecondGallery() {
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number | null>(null);

  const openPopup = (index: number) => {
    setSelectedMediaIndex(index);
  };

  const closePopup = () => {
    setSelectedMediaIndex(null);
  };

  const showPreviousMedia = () => {
    setSelectedMediaIndex((prevIndex) => (prevIndex === null ? null : (prevIndex - 1 + media.length) % media.length));
  };

  const showNextMedia = () => {
    setSelectedMediaIndex((prevIndex) => (prevIndex === null ? null : (prevIndex + 1) % media.length));
  };

  return (
    <div className="gallery">
      {media.map((item, index) => (
        <div key={index} onClick={() => openPopup(index)} className="gallery-item">
          {item.type === 'image' ? (
            <img src={item.src} alt={`Gallery ${index + 1}`} className="gallery-image" />
          ) : (
            <video src={item.src} className="gallery-video" />
          )}
        </div>
      ))}

      {selectedMediaIndex !== null && (
        <div className="popup">
          <div className="popup-content">
            <CloseIcon className="close" onClick={closePopup} />
            {media[selectedMediaIndex].type === 'image' ? (
              <img src={media[selectedMediaIndex].src} alt={`Selected ${selectedMediaIndex + 1}`} className="popup-media" />
            ) : (
              <video src={media[selectedMediaIndex].src} controls muted loop className="popup-media" />
            )}
            <div className="popup-controls">
              <button onClick={showPreviousMedia} className='btn-a blue-btn pr-btn'><KeyboardArrowLeftIcon /></button>
              <button onClick={showNextMedia} className='btn-a orange-btn fr-btn'><KeyboardArrowRightIcon /></button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
