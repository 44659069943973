import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "../css/page css/FirstGallery.css";

const g1 = require("../images/IMPORT-AUG-24/1.jpeg");
const g2 = require("../images/IMPORT-AUG-24/2.jpeg");
const g3 = require("../images/IMPORT-AUG-24/3.jpeg");
const g4 = require("../images/IMPORT-AUG-24/4.jpeg");
const g5 = require("../images/IMPORT-AUG-24/5.jpeg");
const g6 = require("../images/IMPORT-AUG-24/6.jpeg");
const g7 = require("../images/IMPORT-AUG-24/7.jpeg");
const g8 = require("../images/IMPORT-AUG-24/8.jpeg");
const g9 = require("../images/IMPORT-AUG-24/9.jpeg");
const g10 = require("../images/IMPORT-AUG-24/10.jpeg");
const g11 = require("../images/IMPORT-AUG-24/11.jpeg");
const g12 = require("../images/IMPORT-AUG-24/12.jpeg");
const g13 = require("../images/IMPORT-AUG-24/13.jpeg");
const g14 = require("../images/IMPORT-AUG-24/14.jpeg");
const g15 = require("../images/IMPORT-AUG-24/15.jpeg");
const g16 = require("../images/IMPORT-AUG-24/16.jpeg");
const g17 = require("../images/IMPORT-AUG-24/17.jpeg");
const g18 = require("../images/IMPORT-AUG-24/18.jpeg");
const v1 = require("../images/IMPORT-AUG-24/19.mp4");
const v2 = require("../images/IMPORT-AUG-24/20.mp4");

const media = [
  { type: "video", src: v1 },
  { type: "video", src: v2 },
  { type: "image", src: g1 },
  { type: "image", src: g2 },
  { type: "image", src: g3 },
  { type: "image", src: g4 },
  { type: "image", src: g5 },
  { type: "image", src: g6 },
  { type: "image", src: g7 },
  { type: "image", src: g8 },
  { type: "image", src: g9 },
  { type: "image", src: g10 },
  { type: "image", src: g11 },
  { type: "image", src: g12 },
  { type: "image", src: g13 },
  { type: "image", src: g14 },
  { type: "image", src: g15 },
  { type: "image", src: g16 },
  { type: "image", src: g17 },
  { type: "image", src: g18 },
];

export default function ThirdGallery() {
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number | null>(
    null
  );

  const openPopup = (index: number) => {
    setSelectedMediaIndex(index);
  };

  const closePopup = () => {
    setSelectedMediaIndex(null);
  };

  const showPreviousMedia = () => {
    setSelectedMediaIndex((prevIndex) =>
      prevIndex === null ? null : (prevIndex - 1 + media.length) % media.length
    );
  };

  const showNextMedia = () => {
    setSelectedMediaIndex((prevIndex) =>
      prevIndex === null ? null : (prevIndex + 1) % media.length
    );
  };

  return (
    <div className="gallery">
      {media.map((item, index) => (
        <div
          key={index}
          onClick={() => openPopup(index)}
          className="gallery-item"
        >
          {item.type === "image" ? (
            <img
              src={item.src}
              alt={`Gallery ${index + 1}`}
              className="gallery-image"
            />
          ) : (
            <video src={item.src} className="gallery-video" />
          )}
        </div>
      ))}

      {selectedMediaIndex !== null && (
        <div className="popup">
          <div className="popup-content">
            <CloseIcon className="close" onClick={closePopup} />
            {media[selectedMediaIndex].type === "image" ? (
              <img
                src={media[selectedMediaIndex].src}
                alt={`Selected ${selectedMediaIndex + 1}`}
                className="popup-media"
              />
            ) : (
              <video
                src={media[selectedMediaIndex].src}
                controls
                muted
                loop
                className="popup-media"
              />
            )}
            <div className="popup-controls">
              <button
                onClick={showPreviousMedia}
                className="btn-a blue-btn pr-btn"
              >
                <KeyboardArrowLeftIcon />
              </button>
              <button
                onClick={showNextMedia}
                className="btn-a orange-btn fr-btn"
              >
                <KeyboardArrowRightIcon />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
